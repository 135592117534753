import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "mr-3 font-normal" }
const _hoisted_4 = { class: "content-data-list" }
const _hoisted_5 = { class: "container-spinner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")
  const _component_InputIconRightGrey = _resolveComponent("InputIconRightGrey")
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")
  const _component_Paginator = _resolveComponent("Paginator")
  const _component_Card = _resolveComponent("Card")

  return (_openBlock(), _createBlock(_component_Card, null, {
    title: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("h6", _hoisted_3, _toDisplayString(_ctx.title), 1),
          (_ctx.route.meta?.permission?.write && _ctx.showBtnAdd)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                label: _ctx.labelBtnAdd,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redirectAdd())),
                icon: "pi pi-plus",
                class: "font-semibold p-button-sm btn-primary"
              }, null, 8, ["label"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode("div", null, [
          _createVNode(_component_InputIconRightGrey, {
            placeholder: "Cari Disini ...",
            modelValue: _ctx.inputSearch,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.inputSearch = $event)),
            canClick: true,
            onSubmitInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitSearch())),
            onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.submitSearch()), ["enter"])),
            type: _ctx.enumBase.input.text,
            icon: _ctx.icon.svg.searchEva
          }, null, 8, ["modelValue", "type", "icon"])
        ])
      ])
    ]),
    content: _withCtx(() => [
      _createVNode("div", _hoisted_4, [
        _withDirectives(_createVNode("div", _hoisted_5, [
          _createVNode(_component_ProgressSpinner, {
            style: {"width":"70px","height":"70px"},
            class: "spinner-data-list"
          })
        ], 512), [
          [_vShow, _ctx.isLoading]
        ]),
        (!_ctx.isLoading)
          ? _renderSlot(_ctx.$slots, "content", {
              key: 0,
              dataItems: _ctx.dataItems
            })
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => [
      (_ctx.paginate.totalRecords > 0)
        ? (_openBlock(), _createBlock(_component_Paginator, {
            key: 0,
            rows: _ctx.paginate.pageSize,
            totalRecords: _ctx.paginate.totalRecords,
            onPage: _ctx.onChangePage
          }, null, 8, ["rows", "totalRecords", "onPage"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}