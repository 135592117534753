
/* eslint-disable vue/no-dupe-keys */
import {
  defineComponent,
  ref,
  toRefs,
  getCurrentInstance
} from 'vue'
import InputSwitch from 'primevue/inputswitch'
import Repository from '@/data/repository'
import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

export default defineComponent({
  name: 'ColumActive',
  props: {
    valueInput: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    idData: {
      type: Number,
      default: () => null
    },
    endpoint: {
      type: String,
      default: ''
    }
  },
  components: {
    InputSwitch
  },
  setup(props, { emit }) {
    const {
      disabled,
      endpoint,
      idData
    } = toRefs(props)
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const IsActive = ref<boolean>(props.valueInput)

    const processActivated = async (value: boolean) => {
      const {
        result,
        error
      } = await new Repository(new Remote(remoteEnum.patch, `${endpoint.value}/${idData.value}`, [
        {
          op: 'replace',
          path: '/isActive',
          value
        }
      ]), null).getResult(false)
      if (!error) {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successChangeStatus, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      }
    }
    const changeIsActive = () => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          processActivated(IsActive.value)
        },
        reject: () => {
          IsActive.value = !IsActive.value
        }
      })
    }
    return {
      IsActive,
      disabled,
      changeIsActive
    }
  }
})
