
/* eslint-disable vue/no-dupe-keys */
import {
  onMounted,
  reactive,
  ref,
  getCurrentInstance,
  defineComponent,
  toRefs,
  PropType
} from 'vue'
import apiUseCase from '@/domain/usecase/apiUseCase'
import returnUrlParams from '@/utils/helpers/returnUrlPrams'
import ProgressSpinner from 'primevue/progressspinner'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    ProgressSpinner
  },
  props: {
    endpoint: {
      type: String,
      default: () => null,
    },
    totalRow: {
      type: Number,
      default: 10
    },
    title: {
      type: String,
      default: 'Title'
    },
    fieldSearch: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    labelBtnAdd: {
      type: String,
      default: 'Tambah'
    },
    showBtnAdd: {
      type: Boolean,
      default: false
    }
  },
  emits: ['add'],
  setup(props, { expose, emit }) {
    const {
      endpoint,
      totalRow,
      title,
      fieldSearch,
      showBtnAdd,
      labelBtnAdd
    } = toRefs(props)
    const app = getCurrentInstance()
    const {
      $enumBase, $icon
    } = app!.appContext.config.globalProperties
    const route = useRoute()
    const enumBase = $enumBase
    const icon = $icon
    const dataItems = ref([])
    const inputSearch = ref('')
    const isLoading = ref(false)
    const paginate = reactive({
      pageNumber: 1,
      pageSize: totalRow.value,
      totalRecords: 0
    })

    const getData = () => {
      isLoading.value = true
      const paramsString = returnUrlParams({
        pageSize: paginate.pageSize,
        pageNumber: paginate.pageNumber,
        filterField: fieldSearch.value,
        search: inputSearch.value
      })
      apiUseCase.get(`${endpoint.value}${paramsString}`).then((response) => {
        const {
          result,
          count,
          error
        } = response
        if (!error) {
          dataItems.value = result
          paginate.totalRecords = count
        }
        isLoading.value = false
      }).catch((err: any) => {
        isLoading.value = false
      })
    }

    const submitSearch = () => {
      paginate.pageNumber = 1
      getData()
    }

    const onChangePage = async (val: any) => {
      paginate.pageNumber = val.page + 1
      getData()
    }

    const redirectAdd = () => {
      emit('add')
    }

    onMounted(() => {
      getData()
    })
    expose({
      getData
    })
    return {
      dataItems,
      paginate,
      isLoading,
      submitSearch,
      inputSearch,
      title,
      enumBase,
      icon,
      onChangePage,
      showBtnAdd,
      labelBtnAdd,
      redirectAdd,
      route,
      getData
    }
  },
})
// defineExpose({
//   getData
// })
