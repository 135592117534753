import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_InputSwitch, {
      modelValue: _ctx.IsActive,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.IsActive = $event)),
      onChange: _ctx.changeIsActive,
      class: "switch-og",
      disabled: _ctx.disabled
    }, null, 8, ["modelValue", "onChange", "disabled"])
  ]))
}